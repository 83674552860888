/**
 * Regular expressions for detecting domains
 * @type {Object}
 */
const DOMAINS_REGEXP = {
  heroku: /^wegotrip.*\.herokuapp\.com$/,
  dev: /^store.dev.surprizeme.ru$/,
  prodRu: /^wegotrip.ru$/,
  prodCom: /^wegotrip.com$/,
};

export default DOMAINS_REGEXP;
