import React, { useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { Helmet } from "react-helmet";
import routes from "../../routes";
import { Api } from "../../functions/fetchFromApi";
import sendEvent from "../../functions/analytics";
import reverseUrl from "../../functions/reverseUrl";
import { parseQueryString } from "../../functions/toQueryString";
import detectRoute from "../../functions/route/detectRoute";
import classNames from "../../functions/classNames";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import { isSSR } from "../../components/NoSSR";
import Button from "../../components/Button";
import Canonical from "../../components/Canonical";
import SignInDefault from "../../components/SignIn";
import withOffer from "../../components/SignIn/withOffer";
import withEmail from "../../components/SignIn/withEmail";
import withPhone from "../../components/SignIn/withPhone";
import withPassword from "../../components/SignIn/withPassword";
import "./Login.css";
import Alternate from "../../components/Alternate";
import DOMAINS_REGEXP from "../../constants/domains/regexp";

/**
 * SignIn component modifications
 * @type {React::Component}
 */
const SignIn = withOffer(withPassword(withEmail(withPhone(SignInDefault))));

/**
 * Header tag of the page
 * Need for escaping HOC's conflicts on component with `getInitialProps`
 */
const LoginHead = withI18n()(function LoginHeadPure({ i18n }) {
  return (
    <Helmet>
      <title>{i18n._(t`Login — WeGoTrip`)}</title>
    </Helmet>
  );
});

/**
 * Temporary component for login
 * @param {Object} $
 * @param {String} $.nextLink - where to redirect after login (default is `/`)
 * @param {SignInScreen} $.startScreen - screen type of `SignIn` component
 * @param {Boolean} $.blockNavigation - hide navigation buttons for `SignIn` navigating
 */
const Login = ({ nextLink = "/", screen: startScreen = "log-in", blockNavigation, user = {} }) => {
  const lang = useCurrentLanguage();
  const { pathname } = useLocation();
  const route = detectRoute(
    routes.filter(r => /login/i.test(r.name)),
    pathname,
  );

  /**
   * Login was success
   */
  if (!isSSR && !user.loading && user.isLogged) {
    window.location.assign(nextLink);
  }

  /**
   * Current login screen
   */
  const [screen, setScreen] = useState(startScreen);
  const [defaultScreen, setDefaultScreen] = useState("log-in");

  /**
   * Choosing content of `UserAccount__request` element
   * (request type - log in or sign up)
   */
  let request = {
    title: <Trans>Already have an account?</Trans>,
    button: <Trans>Log in</Trans>,
  };
  if (screen === "log-in") {
    request = {
      title: <Trans>Don’t have an account?</Trans>,
      button: <Trans>Sign up</Trans>,
    };
  }

  /**
   * Navigation buttons for sign in
   */
  const navButtons = !blockNavigation ? (
    <>
      {[null, "log-in"].indexOf(screen) !== -1 ? (
        <div className="UserAccount__request">
          {request.title}{" "}
          <Button
            theme="simple"
            onClick={() => {
              const signInType = screen ? null : "log-in";
              setDefaultScreen(signInType);
              setScreen(signInType);
              sendEvent("track", "Login Mode Switched", { title: signInType });
            }}
          >
            {request.button}
          </Button>
        </div>
      ) : null}
      {[null, "log-in"].indexOf(screen) === -1 ? (
        <Button
          className="Login__back"
          theme="simple"
          onClick={() => setScreen(defaultScreen)}
          {...(startScreen === "email"
            ? {
                to: { pathname: `/${reverseUrl("login", { lang })}`, search: `?next=${nextLink}` },
                onClick: () => {},
              }
            : {})}
        >
          <Trans>Other login options</Trans>
        </Button>
      ) : null}
    </>
  ) : null;

  return (
    <Root stickyHeader={false} searchInHeader={false}>
      {route ? <Alternate path={`/${reverseUrl(route, { lang })}`} lang={lang} /> : null}
      {route ? <Canonical url={`/${reverseUrl(route, { lang })}`} /> : null}
      <LoginHead />
      <div className={classNames("Login", screen && `Login--${screen}`)}>
        <div className="Login__fields">
          <SignIn
            hideSignInPhone
            backURL={nextLink}
            email={user.email}
            emailConditions={startScreen === "email"}
            socials={["Google", "Facebook"]}
            screen={screen}
            screenChanged={newScreen => setScreen(newScreen)}
          />
        </div>
        {navButtons}
      </div>
    </Root>
  );
};

Login.getInitialProps = withRedirectToKnownLang(async ({ req, res }) => {
  const pathname = req ? req.url : window.location.pathname;
  const query = req ? req.query : parseQueryString(window.location.search);

  let nextLink = query.next || "/";
  const email = query.username;
  const cookies = get(req, "headers.cookie");
  const isEmailOnly = matchPath(pathname, { path: "(/)?:lang([a-z]{2})?/login-magic/" });

  /**
   * Checking if it is login through the magic link (email passed as a param)
   * If right, will login automatically and redirect to page where login popup was opened
   * Works on server
   */
  if (email && isEmailOnly) {
    try {
      const { headers } = await Api.post(`/api/login-magic/${query.magic_code}`, {
        payload: {
          username: email,
        },
        provideHeaders: true,
      });

      /**
       * Allowing login with email on known domains
       *   by replacing `Domain=` cookie field with domain from which request came
       */
      let cookieHeadersString = headers.get("set-cookie");
      let cookieHeaders = [];

      const cookieRegExp = /, [^ ]*=/g;
      let cookieRegExpMatch = cookieRegExp.exec(cookieHeadersString);
      while (cookieRegExpMatch) {
        cookieHeaders.push(cookieHeadersString.slice(0, cookieRegExpMatch.index));
        cookieHeadersString = cookieHeadersString.slice(cookieRegExpMatch.index + 2);
        cookieRegExpMatch = cookieRegExp.exec(cookieHeadersString);
      }
      cookieHeaders.push(cookieHeadersString);

      if (Object.keys(DOMAINS_REGEXP).some(dr => req.headers.host.match(DOMAINS_REGEXP[dr]))) {
        cookieHeaders = cookieHeaders.map(ch =>
          ch.replace(/Domain=[^;]*; /, `Domain=${req.hostname}; `),
        );
      }
      res.header("Set-Cookie", cookieHeaders);
    } catch (error) {
      nextLink += `${nextLink.indexOf("?") === -1 ? "?" : "&"}unsuccessfulemail=${email}`;
    }

    return { redirectTo: nextLink };
  }

  /**
   * Fetching user
   * If successful - loading `nextLink` page
   */
  try {
    const { data } = await Api.get(`/api/profile/`, { cookies });
    if (data) return { redirectTo: nextLink };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return {
    nextLink,
    ...(isEmailOnly ? { screen: "email" } : {}),
  };
});

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(Login);
